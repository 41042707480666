@import url("https://fonts.googleapis.com/css2?family=Mustica+Pro:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body{
margin: 0;
padding: 0
}

.footer-text{
font-size: 12px;
}